<template>
  <v-container fluid class="mb-10">
    <v-row style="width: 96%">
      <v-col cols="12">
        <v-card>
            <v-row class="pa-10">
                  <v-col cols="12">
                    <v-card
                      class=" mb-10"
                      elevation="0"
                     
                    >
                      <v-data-table
                        :headers="UserDetailsHeader"
                        :items="UserArray"
                        :search="searchuser"
                        class="elevation-1"
                        style="background: #f5f5f5"
                        :loading="loading"
                        loading-text="Please wait..."
                      >
                        <template v-slot:top>
                          <v-toolbar elevation="2" style="background: #f5f5f5">
                            <!-- <v-toolbar-title
                              style="margin-left: -14px"
                              class="mt-2 mb-2"
                              >All In</v-toolbar-title
                            > -->
                            <div>
                                <p class="mb-0 text-h4">Manager Responsibility</p>
                            </div>

                            <v-spacer></v-spacer>
                            <div>
                              <v-text-field
                                v-model="searchuser"
                                append-icon="mdi-magnify"
                                label="Search"
                                filled
                                rounded
                                dense
                                hide-details
                              ></v-text-field>
                            </div>
                            <v-divider class="mx-4" inset vertical></v-divider>
                            <!-- <div>
                                <v-btn color="primary" @click="AddUser">
                                  Add User
                                </v-btn>
                              </div> -->
                          </v-toolbar>
                        </template>
                        <template v-slot:item.actions="{ item }">
                          <v-btn
                            v-if="item.active"
                            shaped
                            text
                            @click="makeInactive(item)"
                            color="primary"
                            style="padding-left:0!important;"
                          >
                            Make inactive
                          </v-btn>

                          <v-btn
                            v-else
                            shaped
                            text
                            @click="makeActive(item)"
                            color="red"
                             style="padding-left:0!important;"
                          >
                            Make Active
                          </v-btn>
                        </template>
                      </v-data-table>
                    </v-card>
                  </v-col>
                </v-row>
        </v-card>
      </v-col>
    </v-row>

    <v-snackbar v-model="snackbar" centered :color="color">
      {{ text }}

      <template v-slot:action="{ attrs }">
        <v-btn color="white" text v-bind="attrs" @click="snackbar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </v-container>
</template>


<script>
export default {
  data: () => ({
    text: "",
    loading: true,
    snackbar: false,
  searchuser:"",
    search: "",
    color:"",
    text:"",
   
    UserDetailsHeader: [
      { text: "House Name", value: "name", sortable: false },
      { text: "Location", value: "location", sortable: false },
      { text: "Status", value: "actions", sortable: false },
    ],
    UserArray: [],
  }),

  methods: {
    initialize() {
      axios
        .get(`manager/user_information/${this.$route.query.id}`)
        .then((response) => {
          this.UserArray = response.data.houses;
          this.loading = false;
          // console.log("this is array after click", this.UserArray);
        });
    },
 
    makeInactive(item) {
  
      // const index = this.UserArray.indexOf(item);
      axios
        .post(`manager/active_manager/0/${item.manager_id}/`)
        .then((response) => {
          if (response.data.success) {
       
            this.text = response.data.message;
            this.color = "success";
             location.reload();
        //  Object.assign(this.UserArray[index], response.data.data);
          } else {
            this.text = response.data.message;
            this.color = "error";
          }
        });
    },
    makeActive(item) {
      // const index = this.UserArray.indexOf(item);
      axios
        .post(`manager/active_manager/1/${item.manager_id}/`)
        .then((response) => {
          if (response.data.success) {
            
            this.text = response.data.message;
            this.color = "success";
            //  Object.assign(this.UserArray[index], response.data.data.is_active);
             location.reload();
          } else {
            this.text = response.data.message;
            this.color = "error";
          }
        });
    },
  },
  mounted() {
    this.initialize();
  },
};
</script>


<style >
.v-data-table > .v-data-table__wrapper > table > tbody > tr > th,
.v-data-table > .v-data-table__wrapper > table > thead > tr > th,
.v-data-table > .v-data-table__wrapper > table > tfoot > tr > th {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  font-size: 1.2rem;
  height: 48px;
}
</style>


